<template>
  <div>
    <div
      class="font-weight-bold d-none d-sm-inline"
      v-on:click="reserveOnDesktop(clubConfig)"
    >
      <slot></slot>
    </div>
    <div
      class=" font-weight-bold d-inline-xs d-sm-none "
      v-on:click="reserveOnMobile(clubConfig)"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
// import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  name: 'RsvpMiniParty',
  props: {
    klass: {
      type: String,
      default: 'btn-sm'
    },
    clubConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    event: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {

    reserveOnDesktop(club) {
      this.rsvpDesktopDialog(club);
      // this.onFreeConciergeClick('desktop', club);
    },
    reserveOnMobile(club) {
      this.rsvpMobileDialog(club);
      // this.onFreeConciergeClick('mobile', club);
    },
    onFreeConciergeClick(channel, club) {
      let value = 30;
      let eventSlug = club.event.slug;
      if (channel === 'desktop') {
        value = 20;
      }
      window.ga('send', 'event', 'reservations', 'Clubs: Info & Reservations', eventSlug, value);
      this.$rollbar.info('Mini Party Info: ' + club.event.slug, {name: encodeURIComponent(club.event.title.split("@")[0])});
    },

    replaceTags(string, event) {
      return string.replace('{{date}}', event.metadata.date).replace('{{time}}', event.metadata.start_time).replace('{{event}}', encodeURIComponent(event.title.split("@")[0]));
    },
    rsvpMobileDialog(clubConfig) {
      let message = this.replaceTags(
        this.SMS_MESSAGE_CLUB_SPECIFIC,
        clubConfig.event
      );
      let options = Object.assign({}, this.BASE_MODAL, {
        title: '',
        html: this.getHtml(clubConfig),
        footer: this.mailFooter(clubConfig.event),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.onFreeConciergeClick('mobile', clubConfig);
          open(`https://wa.me/${this.PHONE_NUMBER}?text=${message}`);
        } else if (result.isDenied) {
          this.onFreeConciergeClick('mobile', clubConfig);
          open(`sms:+${this.PHONE_NUMBER}&body=${message}`);
        }
      });
    },

    rsvpDesktopDialog(clubConfig) {
      let options = Object.assign({}, this.BASE_MODAL, {
        title: '',
        html: this.getHtml(clubConfig),
        footer: this.mailFooter(clubConfig.event),
        background: clubConfig.color,
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.rsvpDesktopWhatsQR(clubConfig.name);
        } else if (result.isDenied) {
          this.rsvpDesktopSmsQR(clubConfig.name);
        }
      });
    },


    rsvpDesktopShowQR(club, qrImageUrl) {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<p >Scan the QR and send us a message with your inquiry. We'll be glad to help.</p> `,
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer:
          '<small > We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options).then((result) => {
      });
    },
    rsvpDesktopWhatsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png'
      );
    },
    rsvpDesktopSmsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        'https://imgix.cosmicjs.com/140a0b30-f22f-11ec-a2eb-c1653f3f9199-telegram-cloud-photo-size-1-5014946017338042974-x.jpg'
      );
    },
    getHtml(clubConfig) {
      return `<div class="">
<img src="${clubConfig.event.metadata.main_image.imgix_url + '?w=280&h=180&fit=fillmax&fill=blur'}" class="carrousel-image" height="180px" width="280px">
                <br>
                <br>
                <h3 class="tulum-party-subtitle" style="margin-bottom: 0px;font-size: 1.2rem">${clubConfig.event.title.split("@")[0]}</h3>
                <p>${clubConfig.niceDate}</p>
                <br>
               ${clubConfig.event.content ? `<div class="text-left">${clubConfig.event.content}</div>` : '<small>Secure your spot now</small>  '}
</div>`
    },
    PERSUASIVE_TEXT_FOR_CLUB() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.PERSUASIVE_TEXT_CLUBS.random();
    },
    PERSUASIVE_TEXT_GENERIC() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.PERSUASIVE_TEXT.random();
    },
    mailto: (event) => `mailto:bookings+${event.title.toLowerCase().replaceAll("@", '').replaceAll("|", '').replaceAll(/\s+/g, '-') || ''}@tulum.party`,
    mailFooter(event) {
      return `<div class="text-center">
          <br>
          <a href="${this.mailto(event)}" class="btn btn-sm btn-primary text-center" > via email</a>
          <br>
          <br>
          <small>We operate from 9AM - 10PM / Answer within 15 mins</small>
        </div>`;
    },
  },

  data: () => {
    const TITLE =
      '<span style="font-weight: bold;font-size: 16px;color: #efefef;letter-spacing: 5px;font-weight: bolder">TULUM<span style="color:mediumaquamarine;font-size: 33px" >.</span>PARTY</span>\n';
    return {
      PHONE_NUMBER: 524421867547,
      BASE_MODAL: {
        backdrop: true,
        html: '',
        showCloseButton: true,
        title: TITLE,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'WhatsApp',
        denyButtonText: `SMS`,
        imageWidth: 250,
        imageHeight: 250,
        confirmButtonColor: 'black',
        denyButtonColor: 'black',
        imageAlt: 'Custom image',
        background: '#1a1818',
        onBeforeOpen: () => {

        },
      },
      PERSUASIVE_TEXT_CLUBS: [
        "Unlock The Magic - Book Now!",
        "Reserve Now, Party Later !"
      ],
      SMS_MESSAGE_CLUB_SPECIFIC:
        'RSVP:%20{{event}}%0a--------%0aFULL_NAME:%20%0aDATE:%20{{date}}%0aARRIVAL_TIME:%20{{time}}%0aGROUP_SIZE:%20',
    };
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
